import React, { useEffect, useState } from "react"
import { Script } from "gatsby"
import { Container } from "react-bootstrap"

const ElfSightReview = (props) => {
  const [renderComponent, setRenderComponent] = useState(false)

  // scroll to ID
  const [targetId, setTargetId] = useState("chepstow");
  useEffect(() => {
    if (targetId) {
      const element = document.getElementById(targetId);
      if (element) {
        const offset = 100; // Adjust this value based on your fixed header height
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
      // setTargetId(null); // Reset targetId after scrolling
    }
  }, [targetId]);

  const handleScroll = (id) => {
    setTargetId(id);
  };
  // scroll to ID

  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })

    setTimeout(() => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    }, 5000)
  }, [])
  return (
    <section className="elfsight-review-wrapper">
      <Container>
        <ul className="list-inline inner-intro-list elfsight-review-list" id="filter-link">
          <li className="list-inline-item"><a href="javascript:;" className={`link ${targetId === 'chepstow' ? 'active' : ''}`} onClick={() => handleScroll('chepstow')}>Chepstow</a></li>
          <li className="list-inline-item"><a href="javascript:;" className={`link ${targetId === 'ross-on-Wye' ? 'active' : ''}`} onClick={() => handleScroll('ross-on-Wye')}>Ross-on-Wye</a></li>
          <li className="list-inline-item"><a href="javascript:;" className={`link ${targetId === 'monmouth' ? 'active' : ''}`} onClick={() => handleScroll('monmouth')}>Monmouth</a></li>
          <li className="list-inline-item"><a href="javascript:;" className={`link ${targetId === 'usk' ? 'active' : ''}`} onClick={() => handleScroll('usk')}>Usk</a></li>
          <li className="list-inline-item"><a href="javascript:;" className={`link ${targetId === 'forest-of-dean' ? 'active' : ''}`} onClick={() => handleScroll('forest-of-dean')}>Forest of Dean</a></li>
          <li className="list-inline-item"><a href="javascript:;" className={`link ${targetId === 'newport' ? 'active' : ''}`} onClick={() => handleScroll('newport')}>Newport</a></li>
        </ul>
        <h2 id="chepstow">What Our Customers in Chepstow Say</h2>
        <div class="elfsight-app-69641fe1-5198-43a2-ae22-6d50c0a19418" data-elfsight-app-lazy></div>

        <h2 id="ross-on-Wye">What Our Customers in Ross-on-Wye Say</h2>
        <div class="elfsight-app-0e8fd626-3fd5-4a7e-abbd-674213369140" data-elfsight-app-lazy></div>

        <h2 id="monmouth">What Our Customers in Monmouth Say</h2>
        <div class="elfsight-app-d64f0aec-796b-4dee-b387-0baa53f2aac5" data-elfsight-app-lazy></div>

        <h2 id="usk">What Our Customers in Usk Say</h2>
        <div class="elfsight-app-a52d9bde-53ce-40fd-88d8-f82785e3d785" data-elfsight-app-lazy></div>

        <h2 id="forest-of-dean">What Our Customers in Forest of Dean Say</h2>
        <div class="elfsight-app-b94663a9-e099-4bd7-a7fc-c21f35b8c67a" data-elfsight-app-lazy></div>

        <h2 id="newport">What Our Customers in Newport Say</h2>
        <div class="elfsight-app-d8b3c468-b255-4025-b864-9a9435e814fb" data-elfsight-app-lazy></div>
      </Container>
      {renderComponent && <Script src="https://apps.elfsight.com/p/platform.js" strategy="idle" />}
    </section>
  )
}

export default ElfSightReview
